import React, { useContext, useState } from "react";

import heart from "../images/heart.svg";
import heartLiked from "../images/heartLiked.svg";

import { axiosInstance } from "../utils/axiosIntance";
import { Context } from "../context/context";
import { LazyLoadImage } from "react-lazy-load-image-component";

function  LikedElement( eprops )
 {
	const { currentUserId	}                   = useContext( Context );
	const { is_liked	    }                   = useContext( Context );
	const { is_likedR	    }                   = useContext( Context );
	const { is_likedMarket	}                   = useContext( Context );
	const { is_likedMarketR	}                   = useContext( Context );
    const { addFavoriteProduct }                = useContext( Context );
    const { addFavoriteMarket }                 = useContext( Context );
    const { deleteFavoriteProduct }             = useContext( Context );
    const { deleteFavoriteMarket }              = useContext( Context );
    const { addFavoriteProductR }               = useContext( Context );
    const { addFavoriteMarketR }                = useContext( Context );
    const { deleteFavoriteProductR }            = useContext( Context );
    const { deleteFavoriteMarketR }             = useContext( Context );
    var user_id                                 = currentUserId( );
    var type                                    = eprops.type;
    var isrest                                  = eprops?.isrest ? true : false;
    var url                                     = eprops.type === "market" ? 
                                                                  ( isrest ? "food_favourite_restaurant" : "grocery_favourite_market" ) :
                                                  eprops.type === "product" ? 
                                                                  ( isrest ? "food_favourite_meal" : "grocery_favourite_product" ) : "";
    var fav_id                                  = eprops.id;
    var align                                   = eprops?.align === "left" ? "cursor-pointer flex ml-2" : 
                                                  eprops?.align === "center" ? "mr-4 mt-2" : "absolute cursor-pointer top-4 right-4";
  

	const [ liked, setLiked ] 					= useState( eprops.is_liked );
     
    const is_local_liked = ( ) =>
    {
        var is_favorite     = isrest ? type === "product" ? is_likedR( fav_id ) : is_likedMarketR( fav_id ) :
                                       type === "product" ? is_liked( fav_id )  : is_likedMarket( fav_id );
        return is_favorite;
    }

    if ( type == "market" )
        align               = "absolute cursor-pointer top-[10px] p-2 right-[10px] w-[42px] h-[42px] rounded-[100%] bg-white z-20";

	const addFavorite = ( ) => 
	{
        console.log( "adding to favorite " + type );
        console.log( "adding to favorite " + fav_id );
        if ( type === "product" )
        {
            if ( isrest )
                addFavoriteProductR( fav_id );
            else
                addFavoriteProduct( fav_id );
        }
        else
        {
            if ( isrest )
                addFavoriteMarketR( fav_id );
            else
                addFavoriteMarket( fav_id );
        }

        if ( user_id !== "" && url !== "" )
			axiosInstance.post( "/api/" + url, 
			{
				user_id: 	user_id,
				id:         fav_id,
			})
			.then( ( data ) => 
			{
				console.log( "adding favorite" + data.data );
			})
			.catch( ( err ) => 
			{
				console.log( err );
			});
	};

	const deleteFavorite = ( ) => 
	{
        console.log( "deleting from favorite " + type + " " + user_id + " " + fav_id );
        if ( type === "product" )
        { 
            if ( isrest )
                deleteFavoriteProductR( fav_id );
            else
                deleteFavoriteProduct( fav_id );
        }
        else
        {
            if ( isrest )
                deleteFavoriteMarketR( fav_id );
            else
                deleteFavoriteMarket( fav_id );
        }

		if ( user_id !== "" && url !== "" )
			axiosInstance.delete( "/api/" + url, 
			{
                data: 
                {
				    user_id: 	user_id,
				    id:         fav_id,
                }
			})
			.then( ( data ) => 
			{ 
				console.log( "deleting favorite" + data.data );
			})
			.catch( ( err ) => 
			{
				console.log( err );
			});
    };

    return (
        <div>
		{
            user_id !== "" ? 
                (
                    !is_local_liked( fav_id ) ? 
                    (
                        <LazyLoadImage
                            onClick = { ( ) => 
                            {
                                addFavorite( );
                                setLiked( !liked );
                            }}
                            className		= { align }
                            src				= { heart }
                            alt				= ""
                        />
                    ) : 
                    (
                        <LazyLoadImage
                            onClick = { ( ) => 
                            {
                                deleteFavorite( );
                                setLiked( !liked );
                            }}
                            className		= { align }
                            src				= { heartLiked }
                            alt				= ""
                        />
                    )
                ) : null
            }
        </div>
	);

}

export default LikedElement;

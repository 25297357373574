import React, { useState, useEffect, createContext } from "react";
import { axiosInstance } from "../utils/axiosIntance";
import tm from "../lang/tm/home.json";
import en from "../lang/en/home.json";
import ru from "../lang/ru/home.json";

export const Context  = createContext( );

const ContextProvider = ( props ) => 
{
  //let localFav;
  let localPro;
  let localProFood;
  //const favHarytlar       = localStorage.getItem( "FavHarytlar" );
  const localProduct      = localStorage.getItem( "BasketProducts" );
  const localProductFood  = localStorage.getItem( "BasketProductsFood" );

  //-----------------------------------------------------------------
  const [ is_logged, set_is_logged ]                     = useState( false );
  const [ favoriteProducts, setFavoriteProducts ]        = useState( [ ] );
  const [ favoriteMarkets, setFavoriteMarkets ]          = useState( [ ] );
  //const [favoriteProductsFood, setFavoriteProductsFood] = useState( [] );
  //-----------------------------------------------------------------
  const [ favoriteProductIds, setFavoriteProductIds ]    = useState( [ ] );
  const [ favoriteMarketIds, setFavoriteMarketIds ]      = useState( [ ] );

  const [ favoriteProductsR, setFavoriteProductsR ]      = useState( [ ] );
  const [ favoriteMarketsR, setFavoriteMarketsR ]        = useState( [ ] );
  const [ favoriteProductIdsR, setFavoriteProductIdsR ]  = useState( [ ] );
  const [ favoriteMarketIdsR, setFavoriteMarketIdsR ]    = useState( [ ] );

if ( localProduct )
  {
    localPro            = JSON.parse( localProduct );
  } 
  else 
  {
    localPro            = [ ];
  }

  if ( localProductFood ) 
  {
    localProFood        = JSON.parse( localProductFood );
  } 
  else 
  {
    localProFood        = [ ];
  }

  /* if ( favHarytlar ) 
  {
    localFav            = JSON.parse( favHarytlar );
  } 
  else 
  {
    localFav            = [ ];
  }
 */
  //const [ favorites, setFavorites ]     = useState( localFav );
  const [ basket, setBasket ]           = useState( localPro );
  const [ basketFood, setBasketFood ]   = useState( localProFood );

  const currentUser = ( ) =>
  {
    return JSON.parse( localStorage.getItem( "userData" ) );
  }
  const currentUserId = ( ) =>
  {
    var user    = currentUser( );
    return ( user === null || user?.id === undefined ) ? "" : user.id.trim( );
  }
  const currentUserPhone = ( ) =>
  {
    var user    = currentUser( );
    return ( user === null || user?.phone_number === undefined ) ? "" : user.phone_number.trim( );
  }

  const checkLogin = ( ) => 
  {
    const data    = JSON.parse( localStorage.getItem( "userData" ) );
    if ( data?.id !== undefined ) 
      set_is_logged( true );
    else
      set_is_logged( false );
  };

  useEffect( ( ) => 
  {
    checkLogin( );
    getFavoriteProducts( );
    getFavoriteMarkets( );
    getFavoriteProductsR( );
    getFavoriteMarketsR( );
  }, [ ] );

  useEffect( ( ) => 
  {
    localStorage.setItem( "BasketProducts",  JSON.stringify( basket ) );
  }, [ basket ] );

  useEffect( ( ) => 
  {
    localStorage.setItem( "BasketProductsFood", JSON.stringify( basketFood ) );
  }, [ basketFood ]);

  // Предотвращает перезапрос данных на время изменения фильтра цены
  // При движениях мыши
  let [ gStopRefresh, setgStopRefresh ]	            = useState( false );

  // Предотвращаяет зыцикливание при измении цены и отмене фильтров на цену
  let [ gPriceFilterClear, setgPriceFilterClear ]   = useState( false );

  // Текущий диапазон цен фильтра
  let [ gPriceRange, setgPriceRange ]               = useState( [ 0, 1000 ] );
  const setGlobalPriceRange = ( range ) =>
   {
      if ( range[ 0 ] === 0 && range[ 1 ] === 1000 )
        setgPriceFilterClear( true );
      
      setgPriceRange( range );
   };

   // Выбранная сортировка списка
   let [ gSortMenu, setgSortMenu ]                  = useState( "Default" );
   const setGlobalSortMenu = ( item ) =>
    {
       setgSortMenu( item );
   };

   // Отмеченные магазины для фильтрации данных
   let [ gSelectedMarkets, setgSelectedMarkets ]	    = useState( [ ] );
   const setGlobalSelectedMarkets = ( array ) =>
   {
       setgSelectedMarkets( array );
   };

   const clearGlobalFilter = ( ) =>
   {
      setgSelectedMarkets( [ ] );
      setgSelectedBrands( [ ] );
      setGlobalPriceRange( [ 0, 1000 ] );
   };

   let [ gAllMarkets, setgAllMarkets ]	              = useState( [ ] );
   const setGlobalAllMarkets  = ( array ) =>
    {
       setgAllMarkets( array );
    };

   // Отмеченные бренды для фильтрации данных
   let [ gSelectedBrands, setgSelectedBrands ] 	    = useState( [ ] );
   const setGlobalSelectedBrands = ( array ) =>
    {
       setgSelectedBrands( array );
    };

   let [gAllBrands, setgAllBrands] 	                = useState( [ ] );
   const setGlobalAllBrands = ( array ) =>
    {
       setgAllBrands( array );
    };

    // Текущая выбранна категория
    // Используется для отслеживания изменения выбора в списке категорий
    let [ gCurrentCategory, setgCurrentCategory ]	  = useState( "" );

   // Выбранный язык системы
   let [ dil, setDil ]                              = useState( "TM" );
  useEffect( ( ) => 
  {
    let dilData = localStorage.getItem( "OnlineDil" );
    if ( dilData ) 
    {
      setDil(JSON.parse( dilData ) );
    } 
    else 
    {
      setDil( "TM" );
    }
  }, [ ] );

  const ChangeDil = (event) => 
  {
    setDil( event );
    localStorage.setItem( "OnlineDil", JSON.stringify( event ) );
  };

  const commonTranslate = ( keyString ) =>
  {
    return dil === "TM" ? tm[ keyString ] : dil === "RU" ? ru[ keyString ] : en[ keyString ];
  }

  // Форматный вывод количества товара
  const formatQuantity = ( qty, setqstr ) =>
	{
		if ( qty == null ) return "";
    if ( qty < 0 )
      qty       = 0

		var qstr		= qty.toFixed( setqstr ? 3 : 0 );
		if ( setqstr )
		{
			// убираем конечные нули
			var zcount	= 0;
			if ( qstr[ qstr.length - 1 ] === "0" )
			{
				zcount	  = 1;
				if ( qstr[ qstr.length - 2 ] === "0" )
				{
					zcount	= 2;
					if ( qstr[ qstr.length - 3 ] === "0" )
						zcount	= 4;		// Запятую тоже убираем
				}
				qstr	    = qstr.substring( 0, qstr.length - zcount );
			}
		}
    return qstr;
	};

  // Удаление товара из корзины
  // Необходимо удалить товар из массива магазина
  // Если в магазине не осталось товаров, то удалить и сам магазин 
  const removeWare = ( wareId, restrant ) => 
  {
//    console.log( wareId );
    let marketIndex = -1;
    let wareIndex   = -1;
    let array       = restrant ? basketFood : basket;
    array?.map( ( market, i ) => 
    {
        market?.products?.map( ( item, index ) => 
        {
          if ( item.ware.id === wareId ) 
          {
            wareIndex   = index;
            marketIndex = i;
          }
          return "";
        });
        return "";
    });
    if ( marketIndex >= 0 && wareIndex >= 0 )
    {
//      console.log( "array[ marketIndex ].products.length = " + array[ marketIndex ].products.length )
//      console.log( array )
      if ( array[ marketIndex ].products.length === 1 )    // Если последний товар, то удаляем и магазин
      {
        array.splice( marketIndex, 1);
      }
      else 
      {
        array[ marketIndex ].products.splice( wareIndex, 1);
      }
    }
    if ( restrant )
      setBasketFood( [...array] );
    else
      setBasket( [...array] );
  };

  const updateBasket = ( ware, quantity, restrant, skipremove ) => 
  {
//    console.log( "updateBasket =>" );
//    console.log( ware );
    if ( quantity <= 0 )
    {
      if ( !skipremove )
        removeWare( ware.id, restrant )
      quantity        = 0;
    }
    let marketIndex   = -1;
    let wareIndex     = -1;
    let array         = restrant ? basketFood : basket;
    if ( array.length > 0 ) 
    {
//      console.log( "map bashlady:", array );
      array?.map( ( market, i ) => 
      {
//        console.log( market );
//        console.log( ware  );
        if ( market.id === ware?.market.id ) 
        {
//          console.log( "market bar" );
          marketIndex     = i;
          market.products?.map( ( item, index ) => 
          {
//            console.log( item );
            if ( item.ware.id === ware.id ) 
            {
//              console.log( "haryt bar:", item.ware.id );
              wareIndex   = index;
              market.products[ index ].quantity = quantity;
            }
            return ""; 
          });

          // Если магазин для товара уже есть, а самого товара нет, добавляем товар в магазин
          if ( marketIndex >= 0 && wareIndex < 0 )
          {
            market.products.push(
            {
              quantity:   quantity,
              product_id: ware.id,
              ware:       ware,
              fractqty:   ware.fractqty
            });
          } 
        }
        return "";
      }
      );
      
    } 
    
    // Нет ни магазина, ни товара
    if ( marketIndex < 0 ) 
    {
//      console.log("market yok : haryt bar:");
      let market  = 
      {
        id:     ware.market.id,
        img:    ware.market.img,
        name:   ware.market.name,
        products: 
        [
          { 
            quantity:   quantity, 
            product_id: ware.id, 
            ware:       ware,
            fractqty:   ware.fractqty
          }
        ],
      };
      array.push( market );
    }

    if ( restrant )
      setBasketFood( [...array] );
    else
      setBasket( [...array] );

//    console.log("gobasket", basket);
  };

  const clearBasket = ( ) => 
  {
    setBasket( [ ] );
  };

    const clearBasketFood = ( ) => 
  {
    setBasketFood( [ ] );
  };

  //---------------------------------------------------------------------------------------------
  // Категории товаров
  const getMarketsForFilter = ( ) =>
	{
		let marketsForFilter	= [ ];
		gSelectedMarkets.map( ( item, index ) => { marketsForFilter.push( item.id  ); return ""; } );
		return marketsForFilter;
	};

	const getBrandsForFilter = ( ) =>
	{
		let brandsForFilter	= [ ];
		gSelectedBrands.map( ( item, index ) => { brandsForFilter.push( item.id  ); return ""; } );
		return brandsForFilter;
	};

  const getPriceRangeMin = ( ) =>
  {
    return ( gPriceRange[ 0 ] == 0 && gPriceRange[ 1 ] == 1000 ) ? "" : gPriceRange[ 0 ];
  };
  const getPriceRangeMax = ( ) =>
  {
    return ( gPriceRange[ 1 ] == 1000 && gPriceRange[ 0 ] == 0 ) ? "" : gPriceRange[ 1 ];
  };
  const getSortingType = ( ) =>
  {
    if ( gSortMenu === "PriceMin" || gSortMenu === "PriceMax" )
      return "price";
    if ( gSortMenu === "Popularity" )
      return "rating";

      return "name";
  };
  const getSortingOrder = ( ) =>
  {
    if ( gSortMenu === "PriceMin" || gSortMenu === "Default" )
      return "ASC";

      return "DESC";
  };

  //---------------------------------------------------------------------------------------------
	const inputTeleHandler = ( value ) => 
	{
		// Получаем только цифры из введенного значения и потом расставляем согласно маске
		const telefonPRX    = "+993 ";
		value       		= value.trimLeft( ).trimRight( );
		var telnum  		= telefonPRX;
		var inprx   		= true;
		var dnum    		= 0;
		for ( var idx = 0; idx < value.length; idx++ )
		{
			if ( inprx && idx < 5 && value[ idx ] === telnum[ idx ] )
			{
				dnum++;
				continue;
			}
			inprx     = false;
	
			if ( value[ idx ] >= '0' && value[ idx ] <= '9' )
			{
				//if ( dnum == 4 ) telnum += "(";
				/*else*/ if ( dnum === 7 ) telnum += " ";
				//else if ( dnum == 9 || dnum == 11 ) telnum += "-";
				
				if ( dnum <= 12 )
				telnum  +=  value[ idx ];
				dnum ++;
			}
		}
		return( telnum );
	};

  const inputQuantityHandler = ( value, fraction ) => 
  {
      // Получаем только цифры из введенного значения и потом расставляем согласно маске
      value         = value.trimLeft( ).trimRight( );
      var quantity  = "";
      var dnum      = 0;
      var ffound    = false;
      for ( var idx = 0; idx < value.length; idx++ )
      {
//        console.log( value[idx]);
        if ( ( value[ idx ] === "." || value[ idx ] === "," ) && !ffound )
        {
          if ( quantity.length === 0 )
            quantity  = "0";
          if ( fraction )
          {
            quantity  += ".";
            ffound  = true;
            dnum    = 0;
          }
        }

        if ( value[ idx ] >= '0' && value[ idx ] <= '9' )
        {
            if ( ( ffound && dnum < 3 ) || ( !ffound && dnum < 6 ) )
              quantity  +=  value[ idx ];
            dnum ++;
        }
      }
//      console.log( "quantity= " + quantity );
//      return ( quantity.length == 0 ? "0" : quantity );
      return quantity;
  };

  //---------------------------------------------------------------------------------------------
  const logout = ( ) => 
  {
    localStorage.removeItem("BasketProducts");
    localStorage.removeItem("OnlineDil");
    localStorage.removeItem("userData");
    checkLogin();
  };

  const getFavoriteProducts = ( ) => 
  {
//    console.log( "getFavoriteProducts = ( ) => " );
    let user_id       = currentUserId( );
    if ( user_id !== "" )
    {
        axiosInstance.get( "/api/grocery_favourite_products", 
        {
            params: 
            {
              lang:     dil,
              user_id:  user_id,
              limit:    99999
            },
        }
      )
      .then( ( data ) => 
        {
          setFavoriteProducts( data.data.body );

          let arrayIds    = [ ];
          let array       = data.data.body;
          array.map (( item, idx ) =>
          {
            arrayIds.push( item?.id );
            return 0;
          } );
          setFavoriteProductIds( arrayIds );
        }
      )
        .catch( ( err ) => 
        {
          console.log( err );
        }
      );
    }
  };

  const getFavoriteProductsR = ( ) => 
  {
    let user_id       = currentUserId( );
    if ( user_id !== "" )
    {
        axiosInstance.get( "/api/food_favourite_meals", 
        {
            params: 
            {
              lang:     dil,
              user_id:  user_id,
              limit:    99999
            },
        }
      )
      .then( ( data ) => 
        {
          setFavoriteProductsR( data.data.body );

          let arrayIds    = [ ];
          let array       = data.data.body;
          array.map (( item, idx ) =>
          {
            arrayIds.push( item?.id );
            return 0;
          } );
          setFavoriteProductIdsR( arrayIds );
        }
      )
        .catch( ( err ) => 
        {
          console.log( err );
        }
      );
    }
  };

  const getFavoriteMarkets = ( ) => 
  {
    let user_id       = currentUserId( );
    if ( user_id !== "" )
    {
        axiosInstance.get( "/api/grocery_favourite_markets", 
        {
            params: 
            {
              lang:     dil,
              user_id:  user_id,
              limit:    99999
            },
        }
      )
      .then( ( data ) => 
        {
          setFavoriteMarkets( data.data.body );

          let arrayIds    = [ ];
          let array       = data.data.body;
          array.map (( item, idx ) =>
          {
            arrayIds.push( item?.id );
            return 0;
          } );
          setFavoriteMarketIds( arrayIds );
        }
      )
        .catch( ( err ) => 
        {
          console.log( err );
        }
      );
    }
  };

  const getFavoriteMarketsR = ( ) => 
  {
    let user_id       = currentUserId( );
    if ( user_id !== "" )
    {
        axiosInstance.get( "/api/food_favourite_restaurants", 
        {
            params: 
            {
              lang:     dil,
              user_id:  user_id,
              limit:    99999
            },
        }
      )
      .then( ( data ) => 
        {
          setFavoriteMarketsR( data.data.body );

          let arrayIds    = [ ];
          let array       = data.data.body;
          array.map (( item, idx ) =>
          {
            arrayIds.push( item?.id );
            return 0;
          } );
          console.log( "setFavoriteMarketsR" );
          console.log( data.data.body );
          setFavoriteMarketIdsR( arrayIds );
        }
      )
        .catch( ( err ) => 
        {
          console.log( err );
        }
      );
    }
  };

  const is_liked = ( id ) => 
  {
    if ( favoriteProductIds?.length > 0 )
      return favoriteProductIds.indexOf( id ) >= 0;

    return false;
  };

  const is_likedR = ( id ) => 
  {
    if ( favoriteProductIdsR?.length > 0 )
      return favoriteProductIdsR.indexOf( id ) >= 0;

    return false;
  };

  const is_likedMarket = ( id ) => 
  {
    if ( favoriteMarketIds?.length > 0 )
      return favoriteMarketIds.indexOf( id ) >= 0;

    return false;
  };

  const is_likedMarketR = ( id ) => 
  {
    if ( favoriteMarketIdsR?.length > 0 )
      return favoriteMarketIdsR.indexOf( id ) >= 0;

    return false;
  };

  const addFavoriteProduct = ( id ) => 
  {
//    console.log( "addFavoriteProduct" );
    let user_id       = currentUserId( );
    if ( user_id !== "" )
    {
      let arrayIds    = favoriteProductIds;
      arrayIds.push( id );
//      console.log( favoriteProductIds );
      setFavoriteProductIds( arrayIds );
    }
  };

  const deleteFavoriteProduct = ( id ) => 
  {
//    console.log( "deleteFavoriteProduct" );
    let user_id       = currentUserId( );
    if ( user_id !== "" )
    {
      let arrayIds    = favoriteProductIds.filter( ( item, idx ) => item !== id );
      setFavoriteProductIds( arrayIds );
//      console.log( favoriteProductIds );
  }
  };

  const addFavoriteMarket = ( id ) => 
  {
    let user_id       = currentUserId( );
    if ( user_id !== "" )
    {
      let arrayIds    = favoriteMarketIds;
      arrayIds.push( id );
      setFavoriteMarketIds( arrayIds );
//      console.log( arrayIds );
    }
  };

  const deleteFavoriteMarket = ( id ) => 
  {
    let user_id       = currentUserId( );
    if ( user_id !== "" )
    {
      let arrayIds    = favoriteMarketIds.filter( ( item, idx ) => item !== id );
      setFavoriteMarketIds( arrayIds );
    }
  };

  const addFavoriteProductR = ( id ) => 
  {
//    console.log( "addFavoriteProductR" );
    let user_id       = currentUserId( );
    if ( user_id !== "" )
    {
      let arrayIds    = favoriteProductIdsR;
      arrayIds.push( id );
//      console.log( favoriteProductIdsR );
      setFavoriteProductIdsR( arrayIds );
    }
  };

  const deleteFavoriteProductR = ( id ) => 
  {
//    console.log( "deleteFavoriteProductR" );
    let user_id       = currentUserId( );
    if ( user_id !== "" )
    {
      let arrayIds    = favoriteProductIdsR.filter( ( item, idx ) => item !== id );
      setFavoriteProductIdsR( arrayIds );
//      console.log( favoriteProductIdsR );
  }
  };

  const addFavoriteMarketR = ( id ) => 
  {
    let user_id       = currentUserId( );
    if ( user_id !== "" )
    {
      let arrayIds    = favoriteMarketIdsR;
      arrayIds.push( id );
      setFavoriteMarketIdsR( arrayIds );
//      console.log( arrayIds );
    }
  };

  const deleteFavoriteMarketR = ( id ) => 
  {
    let user_id       = currentUserId( );
    if ( user_id !== "" )
    {
      let arrayIds    = favoriteMarketIdsR.filter( ( item, idx ) => item !== id );
      setFavoriteMarketIdsR( arrayIds );
    }
  };
  //---------------------------------------------------------------------------------------------

  return (
    <Context.Provider
    value=
    {
      {
        is_logged,
        currentUser,
        currentUserId,
        currentUserPhone,
        commonTranslate,
        favoriteProducts,
        favoriteMarkets,
        favoriteProductsR,
        favoriteMarketsR,
        checkLogin,
        logout,
        is_liked,
        is_likedR,
        is_likedMarket,
        is_likedMarketR,
        getFavoriteProducts,
        getFavoriteMarkets,
        addFavoriteProduct,
        addFavoriteMarket,
        deleteFavoriteProduct,
        deleteFavoriteMarket,
        getFavoriteProductsR,
        getFavoriteMarketsR,
        addFavoriteProductR,
        addFavoriteMarketR,
        deleteFavoriteProductR,
        deleteFavoriteMarketR,

        dil,
//        favorites,
        ChangeDil,
        gPriceRange,
        setGlobalPriceRange,
        gSortMenu,
        gSelectedMarkets, 
        setGlobalSelectedMarkets,
        gAllMarkets, 
        setGlobalAllMarkets,
        gSelectedBrands,
        setGlobalSelectedBrands,
        clearGlobalFilter,
        gAllBrands, 
        setGlobalAllBrands,
        setGlobalSortMenu,

        getMarketsForFilter,
        getBrandsForFilter,
        getPriceRangeMin,
        getPriceRangeMax,
        getSortingType,
        getSortingOrder,
        gStopRefresh,
        setgStopRefresh,
        gPriceFilterClear,
        setgPriceFilterClear,
        gCurrentCategory,
        setgCurrentCategory,

        basket,
        basketFood,
        clearBasket,
        clearBasketFood,
        inputTeleHandler,
        inputQuantityHandler,
        updateBasket,
        formatQuantity,
        removeWare
      }
    }
    >
    { props.children }
    </Context.Provider>
  );
};

export default ContextProvider;

import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import en from "../lang/en/home.json";
import tm from "../lang/tm/home.json";
import ru from "../lang/ru/home.json";
import { Context } from "../context/context";
import { BASE_URL_IMG } from "../utils/axiosIntance";
import { LazyLoadImage } from "react-lazy-load-image-component";
import LikedElement from "./LikedElement";

function ProductCard( props )
 {
	const history 								= useHistory( );
	const { is_liked }							= useContext( Context )
	const [ animation, setAnimation ] 			= useState( false );
	const [ count, setCount ] 					= useState( 1 );
	const [ quantityString, setQuantityString ]	= useState( "1" );

	const {	dil }								= useContext( Context )
	const { updateBasket }						= useContext( Context )
	const { inputQuantityHandler }				= useContext( Context )
	const { formatQuantity }					= useContext( Context )
	const { basket }							= useContext( Context )

	useEffect( ( ) => 
	{
		basket.forEach( ( basket_market ) => 
		{
			basket_market.products.forEach( ( basket_product ) => 
			{
				if ( basket_product.product_id === props.data.id ) 
				{
					setCount( basket_product.quantity ); setAnimation( basket_product.quantity > 0 );

					var str           = formatQuantity( basket_product.quantity, basket_product.fractqty );
					if ( basket_product.fractqty )
						setQuantityString( str )
				}
			});
		});
	}, [ props.data.id ] );	//basket

	let dragStart = undefined;

	const mouseDown = ( ) => 
	{
		dragStart = new Date( );
	};

	const showDetails = ( ) => 
	{
		const cD 	= new Date( );
//		console.log( cD - dragStart );
		if ( cD - dragStart < 1000 ) 
		{
			history.push({ pathname: "/mrt/product/" + props.data.id });
		}
		else
			dragStart = undefined;
	};

	const updateQuantity = ( ware, qty, setqstr ) =>
	{
		updateBasket( ware, qty, false, false );
		setCount( qty ); setAnimation( qty > 0 );

		var str           = formatQuantity( qty, setqstr );
		if ( setqstr )
			setQuantityString( str )
	};

	return (
		<div className="sm:w-[236px] w-[165px] sm:mr-0 mr-1 md2:min-h-[460px] min-h-[375px] flex flex-col justify-between flex-wrap relative">
			<LikedElement  id= { props.data.id } type= 'product' align= 'right' is_liked= { is_liked( props.data.id ) } />
			<LazyLoadImage
				onMouseDown		= { mouseDown }
				onClick			= { showDetails }
				className		= "md2:h-[236px] h-[175px] w-full object-contain rounded-[24px] cursor-pointer"
				src				= { BASE_URL_IMG + props?.img}
				alt				= "image"
			/>
			<p className="font-semi mt-2 md2:text-[22px] text-[20px] text-neutral-900">
				{ props.data?.is_discount
					? props.data?.discount_price.toFixed( 2 )
					: props.data?.price.toFixed( 2 ) }{" "}
				TMT
			</p>

			<div className="flex gap-[8px] h-[24px] my-[8px]">
				{ props.data?.is_discount && (
					<div className="bg-red px-[3px] py-[3px] rounded-[8px]">
						<p className="text-white md2:text-[15px] text-[12px] m-0 font-semi">
							-{props.data?.discount_percentage.toFixed(2)}%
						</p>
					</div>
				)}
				{ props.data?.is_discount && 
				(
					<p className="text-passive font-regular md2:text-[16px] text-[14px] line-through decoration-red">
						{ props.data?.price.toFixed( 2 )} TMT
					</p>
				)}
			</div>

			<p
				onMouseDown	= { mouseDown }
				onClick		= { showDetails }
				className	= "line-clamp-2 cursor-pointer text-neutral-900 font-regular md2:text-[18px] text-[16px] md2:h-[52px] h-[45px] leading-6 mt-3"
			>
				{ props.text ? props.text : "" }
			</p>

			<p className="line-clamp-2 text-neutral-900 font-semi mt-2  text-[16px] leading-6 ">
				{ props.data?.market?.name }
			</p>
			{!animation && (
				<div
					onClick=
					{ ( ) => 
						{
							updateQuantity( props?.data, 1, true );
						}
					}
					className="custom-button mt-2  select-none bg-green text-white rounded-[16px] text-[18px] h-[50px]"
				>
					{dil === "TM"
						? tm["Sebede goş"]
						: dil === "RU"
						? ru["Sebede goş"]
						: en["Sebede goş"]}
				</div>
			)}
			{animation && (
				<div className="select-none mt-2  bg-green-100 flex justify-evenly text-green py-[10px] rounded-[16px] text-[18px] h-[50px]">
					<button
						onClick=
						{ () => 
							{
								updateQuantity( props?.data, count <= 1 ? 0 : count - 1, true );
							}
						}
						className="text-green bg-green-200 h-[30px] w-[30px] leading-[9px] rounded-[100%] p-2"
					>
						-
					</button>
					{ props?.data.fractqty ? 
					(
						<input
							className	= "text-green bg-green-200 h-[30px] w-[100px] leading-[9px] rounded-[10%]"
							type		= "text"
							value		= { quantityString }
							style		= { ( { textAlign: 'center' } ) }
							id			= "quantity"	
							onChange	= { ( e ) => 
								{
									var qtystr	= inputQuantityHandler( e.target.value, true );
									var qty		= parseFloat( qtystr.length === 0 ? "0" : qtystr );
									setQuantityString( qtystr );

									// Пустое значение или 0 может быть перед вводом дробной части
									// 0 обработаем при выходе из поля
									if ( qty !== 0 )
										updateQuantity( props?.data, qty, false ) 
								}
							}
							onFocus		= { ( e ) => 
								{
									e.target.select( );
								}
							}
							onBlur		= { ( e ) => 
								{
									var qtystr	= inputQuantityHandler( e.target.value, true );
									updateQuantity( props?.data, parseFloat( qtystr.length === 0 ? "0" : qtystr ), true ) 
								}
							}
						/>
					) : ( <p className="text-[16px] text-green">{count}</p> ) }

					<button
						onClick=
						{ ( ) => 
							{
								//console.log("clicked")
								updateQuantity( props?.data, count + 1, true );
							}
						}
						className="text-green bg-green-200 h-[30px] w-[30px] leading-[9px] rounded-[100%] p-2"
					>
						+
					</button>
				</div>
			)}
		</div>
	);
}

export default React.memo( ProductCard );

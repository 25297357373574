import React from "react";
import { useHistory } from "react-router-dom";

import { BASE_URL_IMG } from "../utils/axiosIntance";
import { LazyLoadImage } from "react-lazy-load-image-component";

function BrandCard( props ) 
{
  const history = useHistory( );
  return (
    <div
      onClick         = { ( ) => history.push( { pathname: "/mrt/brend/" + props.data.id } ) }
      className       = "border cursor-pointer border-[#E9EBED] md2:w-[196px] w-[170px] md2:h-[196px] h-[180px] rounded-[8px]">
      <div className  = "flex w-full py-[12px] px-[15px] ">
        <LazyLoadImage
          className   = "w-full md2:h-[127px] h-[114px] object-contain border-b-[1px] border-b-neutral-300"
          src         = { BASE_URL_IMG + props?.img }
          alt         = "brand"
        />
      </div>
      <p className    = "w-full text-center font-medium text-[16px]">
          { props.text }
      </p>
    </div>
  );
}

export default BrandCard;

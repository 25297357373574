import axios from "axios";
import { token } from "./token";
//const BASE_URL = "http://79.173.84.123:52628";
//const BASE_URL_IMG = "http://79.173.84.123:52628/itda/";
//const BASE_URL = "http://192.168.1.3:8181";
//const BASE_URL_IMG = "http://192.168.1.3:8181/itda/";
const BASE_URL = "https://ast.com.tm:8282/";
const BASE_URL_IMG = "https://ast.com.tm:8282/itda/";
// const BASE_URL = "http://119.235.118.211:8080";

const axiosInstance = axios.create({
	baseURL: BASE_URL,
	timeout: 10000000,
	// withCredentials: false,
	headers: {
		Authorization: "Bearer " + token(),
		"Content-Type": "application/json",
		// Accept: "*/*",
		// Authorization:'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwicm9sZSI6ImFkbWluIiwibmFtZSI6IktlcmltIiwiaWF0IjoxNjE2NDUwNjU3fQ.v8iyHYmwNlKVhLUA7LzxybICB8zzbVjRyXeFZbV7IPw'
	},
});

export { BASE_URL, BASE_URL_IMG, axiosInstance };

import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import Logo from "../images/ast_logo.png";
import { LazyLoadImage } from "react-lazy-load-image-component";

const PageLoading = () => {
  return (
    <div className="flex w-full justify-center flex-col items-center mx-auto  h-[85vh] gap-5 md:h-[80vh] md:w-[50vw] sm:w-[60vw] lg:w-[40vw] xl:w-[30vw]">
      <div className="flex w-1/2 gap-4 md2:w-full place-content-center place-items-center">
        <LazyLoadImage src={Logo} alt="Logo" />
        {/* <h1 className="text-2xl font-bold">Söwda Toplumy</h1> */}
      </div>
      {/* <BeatLoader
				size={"20px"}
				color={"#32BB78"}
				loading={true}
				width={"400px"}
			/> */}
    </div>
  );
};

export default PageLoading;
